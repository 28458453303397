import { DialogModal, ErrorSummary, SuccessSummary } from '@engloba-tech/englobity';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAll from '@material-ui/icons/DoneAll';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NAME_SPACE } from 'i18n';

import { useHandleRequest } from 'shared/useHandleRequest';
import { useEditModalStyles } from './editModal.styles';

export function EditModal({
  entity,
  onSubmit,
  handleCloseEditModal,
  onSubmitEditModal,
  onKeepCreating,
  className,
  readonly,
  btnKeepCreating = true,
  title,
  showDefaultButtons,
  customButtons,
  ...props
}) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useEditModalStyles();
  const $formRef = useRef(null);
  const [keepCreating, setKeepCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { request } = useHandleRequest();

  const clearSummaries = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const handleAccept = keepCreating => {
    setKeepCreating(keepCreating);
    clearSummaries();
    $formRef.current.submit();
  };

  const handleSubmit = async element => {
    try {
      await request(async () => {
        const success = await onSubmitEditModal(element);
        if (!success) throw '';
        if (!keepCreating) {
          handleCloseEditModal();
        } else {
          setSuccessMessage(t('common:responses.addedSuccessfully'));
          setErrorMessage(null);
          onKeepCreating();
        }
      });
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    clearSummaries();
    handleCloseEditModal();
  };

  return (
    <>
      {entity && (
        <DialogModal
          fullWidth
          title={title ? title : entity.id ? t('actions.edit') : t('actions.newone')}
          description=""
          isOpen={true}
          id={'edit-modal'}
          className={clsx(className, classes.modal)}
          onClose={handleClose}
          customButtons={customButtons}
          buttons={
            readonly
              ? [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  }
                ]
              : [
                  {
                    children: <CloseIcon />,
                    text: t('common:actions.close'),
                    type: 'secondary',
                    onClick: handleClose
                  },
                  {
                    children: <DoneIcon />,
                    text: t('common:actions.accept'),
                    type: 'primary',
                    onClick: () => handleAccept(false)
                  },
                  !entity.id &&
                    btnKeepCreating && {
                      children: <DoneAll />,
                      text: t('common:actions.createMore'),
                      type: 'primary',
                      onClick: () => handleAccept(true)
                    }
                ]
          }
        >
          {Boolean(successMessage) && <SuccessSummary text={successMessage} />}

          {Boolean(errorMessage) && <ErrorSummary text={t(errorMessage)} />}

          {React.cloneElement(props.children, {
            ...props.children,
            isModal: true,
            formRef: $formRef,
            onSubmit: handleSubmit
          })}
        </DialogModal>
      )}
    </>
  );
}
