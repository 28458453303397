import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { execConfirmModal } from '../../execConfirmModal/execConfirmModal';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';

export function MathButtonDelete({ elementsToDelete, tooltip, text, onConfirm, confirmDescription, onClick }) {
  const { t } = useTranslation();
  const disabled = !elementsToDelete?.length;

  return (
    <ButtonPrimary
      startIcon={<DeleteIcon />}
      disabled={disabled}
      tooltip={{
        title: (disabled && (tooltip?.disabled || t('actions.disabled.select'))) || '',
        placement: 'top'
      }}
      onClick={async () =>
        onClick
          ? await onClick(elementsToDelete, onConfirm)
          : await execConfirmModal({
              title: t('common:actions.remove'),
              description: confirmDescription(elementsToDelete?.length),
              confirmText: t('common:actions.accept'),
              declineText: t('common:actions.cancel'),
              onConfirm: async () => await onConfirm(elementsToDelete)
            })
      }
    >
      {text || t('actions.remove')}
    </ButtonPrimary>
  );
}
