import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NAME_SPACE } from '../../../i18n';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';

export function MathButtonAdd({ disabled, condition, tooltip, text, onClick, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);

  return (
    <ButtonPrimary
      startIcon={<AddCircleIcon />}
      disabled={disabled}
      text={text || t('actions.add')}
      tooltip={{
        title: (disabled && tooltip?.disabled) || '',
        placement: 'top'
      }}
      onClick={onClick}
      {...props}
    >
      {text || t('actions.add')}
    </ButtonPrimary>
  );
}
