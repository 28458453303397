import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ce from '../../../assets/images/ce.jpg';
import form from '../../../assets/images/form1.jpg';
import form2 from '../../../assets/images/form2.jpg';
import form3 from '../../../assets/images/form3.jpg';
import ka from '../../../assets/images/ka.jpg';
import kakd from '../../../assets/images/kakd.jpg';
import q from '../../../assets/images/q.jpg';
import qm from '../../../assets/images/qm.jpg';
import r2 from '../../../assets/images/r2.jpg';

import { useAboutStyles } from './about.styles';

export const About = () => {
  const { t } = useTranslation(NAME_SPACE.ABOUT);
  const classes = useAboutStyles();
  const requiredInputs = ['lb', 'db', 'd', 'q', 'e', 'rhoa', 'qm', 'qeexp', 'ci', 't12'];
  const moreInputs = ['texp', 'cexpci'];
  return (
    <Grid>
      <p className={classes.title}>{t('title')}</p>
      <p style={{ lineHeight: 1.8 }}>
        <span>{t('texts.text')}</span>
        <span>{t('texts.text1')}</span>
        <span></span>
        <div className={classes.form}>
          <img src={form} alt="form" />
        </div>
        <span>{t('texts.text2')}</span>
        <div className={classes.form}>
          <img src={form2} alt="form2" />
        </div>
        <span>{t('connectors.where')}</span>
        <span className={classes.imgQ}>
          <img src={q} alt="q" />
        </span>
        <span>{t('texts.text3')}</span>
        <span className={classes.imgKakd}>
          <img src={kakd} alt="kakd" />
        </span>
        <span>{t('texts.text4')}</span>
        <span className={classes.imgQm}>
          <img src={qm} alt="qm" />
        </span>
        <span>{t('texts.text5')}</span>
        <span className={classes.imgCe}>
          <img src={ce} alt="ce" />
        </span>
        <span>{t('texts.text6')}</span>
      </p>
      <p className={classes.title}>{t('requiredInputs')}</p>
      <ul className={classes.list}>
        {requiredInputs.map(i => (
          <li key={i}>{t(`properties.${i}`)}</li>
        ))}
      </ul>
      <p>{t('texts.text7')}</p>
      <ul className={classes.list}>
        {moreInputs.map(i => (
          <li key={i}>{t(`moreProperties.${i}`)}</li>
        ))}
      </ul>
      <span>{t('texts.text8')}</span>
      <p className={classes.title}>{t('outputs')}</p>
      <p>
        <span>{t('texts.text9')}</span>
      </p>
      <p>
        <span>{t('texts.text10')}</span>
        <span className={classes.imgR2}>
          <img src={r2} alt="r2" />
        </span>
        <span>{t('texts.text11')}</span>
        <div className={classes.form}>
          <img src={form3} alt="form3" />
        </div>
        <span>{t('texts.text12')}</span>
        <span className={classes.imgKa}>
          <img src={ka} alt="ka" />
        </span>
        <span>{t('texts.text13')}</span>
      </p>
      <p className={classes.title}>{t('acknowledgements')}</p>
      <p>{t('texts.text14')}</p>
      <p className={classes.title}>{t('references')}</p>
      <p>{t('texts.text15')}</p>
      <p>{t('texts.text16')}</p>
    </Grid>
  );
};
