import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { useLoading } from 'loading';
import { useCallback, useMemo } from 'react';
import { dateHelper } from 'shared';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';
import { useSimulationStyles } from './useSimulation.styles';

export function useSimulationsBuildTable({ simulations, loadSimulation, handleLoadUnselect, cleanPrediction, t }) {
  const { handleLoading } = useLoading();
  const classes = useSimulationStyles();
  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: t('properties.name')
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: t('properties.description'),
        ellipsis: true
      },
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: t('properties.date'),
        filterType: FILTER_TYPE.DATE,
        className: classes.filterDate
      },
      { id: 'link', action: true }
    ];
  }, [t, classes.filterDate]);

  const loadSelectedSimulation = useCallback(
    id => {
      loadSimulation(id);
      cleanPrediction();
      handleLoadUnselect();
    },
    [loadSimulation, cleanPrediction, handleLoadUnselect]
  );

  const rows = useMemo(() => {
    if (!simulations) {
      return [];
    }
    return simulations?.items.map(simulation => ({
      id: simulation.id,
      name: simulation.name,
      description: simulation.description,
      date: dateHelper.toShortDate(simulation.date),
      link: {
        title: t('actions.link'),
        component: (
          <OpenInNewOutlinedIcon
            onClick={() => {
              handleLoading(() => loadSelectedSimulation(simulation.id));
            }}
          />
        )
      }
    }));
  }, [simulations, t, loadSelectedSimulation, handleLoading]);

  return { headCells, rows };
}
