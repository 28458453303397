import { ViewTable as EnglobityViewTable } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ViewTable(props) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  return (
    <EnglobityViewTable
      {...props}
      emptyText={props.emptyText || t('empty')}
      checkedElementsCountText={t('actions.selected')}
      rowsPerPageText={t('viewTable.rowsPerPage')}
      displayedRowsText={t('viewTable.of')}
      clearFiltersText={t('viewTable.clearFilters')}
      backIconButtonText={t('viewTable.backIconButtonText')}
      nextIconButtonText={t('viewTable.nextIconButtonText')}
      dateFromText={t('actions.from')}
      dateUntilText={t('actions.until')}
      todayDatePickerLabel={t('actions.today')}
      clearDatePickerLabel={t('actions.clear')}
      cancelDatePickerLabel={t('actions.cancel')}
      okDatePickerLabel={t('actions.accept')}
    />
  );
}
