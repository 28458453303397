import { errorToaster, successToaster, useHandleOpen, warningToaster } from '@engloba-tech/englobity';
import { useLoading } from 'loading';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHandleRequest } from 'shared';
import { useRequestParams } from 'shared/useRequestParams';
import { useStateWithBackup } from 'shared/useStateWithBackup';

export function useViewTableData({ service, parentId, mainKey = 'name', secondaryKey }) {
  const { t } = useTranslation();
  const [data, setData, undoData] = useStateWithBackup();
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { paging, sorting, filteredCells, advancedFilters, currentRequestParams, setRequestParams } =
    useRequestParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const { handleLoading } = useLoading();
  const keepAdding = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const get = useCallback(
    async ({ paging, sorting, filteredCells, advancedFilters }) => {
      handleLoading(
        async () =>
          await request(async () => {
            setRequestParams(paging, sorting, filteredCells, advancedFilters);
            const response = parentId
              ? await service.get(parentId, paging, sorting, filteredCells, advancedFilters)
              : await service.get(paging, sorting, filteredCells, advancedFilters);
            if (response) {
              setData(response.data || []);
            }
          }, false)
      );
    },
    [request, setRequestParams, parentId, service, setData, handleLoading]
  );
  const deleteElements = useCallback(
    async elementsToDelete => {
      await request(async () => {
        const dataNotDeleted = elementsToDelete.map(e => data.items.filter(a => a.id !== e.id)[0]);
        await Promise.allSettled(
          elementsToDelete.map(element => {
            setData(prevElement => {
              prevElement.items = dataNotDeleted[0] ? dataNotDeleted : [];
              return { ...prevElement };
            });
            return parentId
              ? service.delete(parentId, element.id, elementsToDelete.length > 1)
              : service.delete(element.id, elementsToDelete.length > 1);
          })
        ).then(responses => {
          if (responses.length <= 1) {
            if (responses[0].status === 'rejected') undoData();
            else get({ paging, sorting, filteredCells, advancedFilters });
            return;
          }

          if (responses.filter(resp => resp.status === 'rejected').length === responses.length) {
            undoData();

            errorToaster(
              t('multiple.deleteFailed'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = elementsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  if (element) {
                    return `${mainKey ? element[mainKey] : 'No key provided'} ${
                      secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                    }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                  }

                  return `Unknown | ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
          } else if (
            responses.some(resp => resp.status === 'rejected') &&
            responses.some(resp => resp.status === 'fulfilled')
          ) {
            warningToaster(
              t('multiple.deleteWithErrors'),
              responses
                .filter(res => res.status === 'rejected')
                .map(resp => {
                  let element = elementsToDelete.find(
                    e =>
                      e.id ===
                      resp.reason.config.url.substring(
                        resp.reason.config.url.lastIndexOf('/') + 1,
                        resp.reason.config.url.length
                      )
                  );
                  return `${mainKey ? element[mainKey] : 'No key provided'}  ${
                    secondaryKey ? '- ' + element[secondaryKey] + ' ' : ' '
                  }| ${t(JSON.parse(resp.reason.response.data).message)}`;
                })
            );
            get({ paging, sorting, filteredCells, advancedFilters });
          } else {
            successToaster(t('request.succes'));
            get({ paging, sorting, filteredCells, advancedFilters });
          }
        });
      });
    },
    [
      request,
      setData,
      parentId,
      service,
      undoData,
      get,
      paging,
      sorting,
      filteredCells,
      advancedFilters,
      t,
      mainKey,
      secondaryKey
    ]
  );

  const handleSelectedItem = id => {
    setSelectedItem(id ? data.items.find(item => item.id === id) : {});
    setErrorInfo(null);
    handleOpen();
  };

  const handleCloseEditModal = useCallback(() => {
    handleClose();
    setSelectedItem(null);
    setSuccessMessage(null);
    setErrorInfo(null);
  }, [handleClose, setErrorInfo]);

  const handleAcceptEditModal = useCallback(createMore => {
    keepAdding.current = createMore;
  }, []);

  return {
    data,
    get,
    deleteElements,
    errorInfo,
    setErrorInfo,
    setData,
    undoData,
    paging,
    sorting,
    filteredCells,
    selectedItem,
    editModalOpen: isOpen,
    handleSelectedItem,
    handleCloseEditModal,
    handleAcceptEditModal,
    successMessage,
    currentRequestParams
  };
}
