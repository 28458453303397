import { errorToaster } from '@engloba-tech/englobity';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { predictions } from 'services';
import { predictionHelper, useHandleRequest } from 'shared';

export function usePrediction() {
  const [predictionData, setPredictionData] = useState();
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { t } = useTranslation();

  function getGraphics(obj) {
    const keysArray = ['n1m1case', 'n2m1case', 'n2m2case', 'n3m1case', 'n3m2case'];
    let matchingValues = [];
    for (let i = 0; i < keysArray.length; i++) {
      let key = keysArray[i];
      if (obj.hasOwnProperty(key)) {
        matchingValues.push(obj[key]);
      }
    }
    return matchingValues;
  }

  const mapPrediction = useCallback(prediction => {
    const fitResultsParsed = prediction.fitResults.replace(/NaN/g, 'null');
    return {
      ...prediction,
      graphics: getGraphics(prediction),
      fitResults: JSON.parse(fitResultsParsed)
    };
  }, []);

  const createPrediction = useCallback(
    async prediction => {
      const mappedPrediction = predictionHelper.mapFromSimulation(prediction);
      const response = await request(
        async () => {
          const response = await predictions.create(mappedPrediction);
          if (response?.data) {
            const prediction = mapPrediction(response.data);
            setPredictionData(prediction);
          }
        },
        false,
        () => setPredictionData()
      );
      if (response?.message) {
        errorToaster(t('request.error'), t('validations.entriesNotValid'));
      }
    },
    [request, mapPrediction, t]
  );

  const cleanPrediction = () => {
    setPredictionData();
  };

  return {
    prediction: predictionData,
    errorInfo,
    setErrorInfo,
    createPrediction,
    cleanPrediction
  };
}
