import { Dropzone, ErrorSummary, Form } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from 'shared';
import * as XLSX from 'xlsx';
import excel from '../../../../../../assets/images/template_import.xls';
import { useFormImportStyles } from './formImport.styles';

export const FormImport = ({ onSubmit, formRef }) => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const [file, setFile] = useState({});
  const [inputs, setInputs] = useState([]);
  const [errorInfo, setErrorInfo] = useState({ message: '', detail: '' });
  const classes = useFormImportStyles();

  const handleSelectFile = file => {
    const dropFile = file[0];
    if (dropFile) {
      setErrorInfo({ message: '', detail: '' });
      const reader = new FileReader();
      reader.readAsBinaryString(dropFile);
      reader.onload = e => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        if (validations(parsedData)) {
          setInputs(parsedData);
          setFile(prevInputs => ({
            ...prevInputs,
            selectedFile: dropFile
          }));
        }
      };
    } else {
      setErrorInfo({ message: t('common:import.validations.noValid') });
    }
  };

  const transformData = () => {
    return inputs.map(item => {
      const keys = Object.keys(item);
      return {
        texp: item[keys[0]],
        cexpci: item[keys[1]]
      };
    });
  };

  const validations = data => {
    if (data.length === 0) {
      setErrorInfo({ message: t('common:import.validations.emptyData') });
      return false;
    }
    if (Object.keys(data[0]).length !== 2) {
      setErrorInfo({ message: t('common:import.validations.columnError') });
      return false;
    }
    setErrorInfo({ message: '', detail: '' });
    return true;
  };

  const handleDeleteFile = () => {
    setFile(prevInputs => ({
      ...prevInputs,
      selectedFile: {}
    }));
    setInputs({});
  };

  const handleSubmit = () => {
    onSubmit(transformData(inputs));
  };

  const headCells = [t('properties.texp'), t('properties.cexpci')];

  return (
    <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      {errorInfo?.message && (
        <Grid item xs={12} className={classes.error}>
          <ErrorSummary text={errorInfo?.message} detail={errorInfo?.detail} />
        </Grid>
      )}
      <p>{t('common:import.descriptionTitle')}</p>
      <p>{t('common:import.description')}</p>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Dropzone
            required
            errorMessage={t('validations.required')}
            accept=".xlsx, .xls"
            file={file?.selectedFile?.path || ''}
            onDrop={handleSelectFile}
            onDeleteFile={handleDeleteFile}
            labelDrop={t('common:import.drop')}
          />
        </Grid>
        <Grid item xs={4}>
          <a href={excel} download="Import-template" target="_blank" rel="noreferrer">
            <ButtonPrimary type="button">{t('common:import.downloadTemplate')}</ButtonPrimary>
          </a>
        </Grid>
      </Grid>
      {inputs.length > 0 && (
        <Grid item xs={12} className={classes.tableContainer}>
          <p>{t('common:import.addDescription', { num: inputs.length })}</p>
          <table className={classes.table}>
            <thead>
              <tr>
                {headCells.map(key => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {inputs.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      )}
    </Form>
  );
};
