import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useAuthStyles = makeStyles(theme => ({
  title: {
    fontWeight: '600',
    fontSize: '24px',
    marginBottom: '30px'
  },
  form: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '& .MuiFormLabel-root.Mui-focused': {
      color: `${color.primary.main} !important`
    },
    '& .MuiFormControl-root .MuiFormLabel-root': {
      fontSize: '16px !important',
      fontWeight: '600 !important',
      marginBottom: '10px !important',
      color: '#000000DE'
    },
    '& .MuiFormControl-root .MuiInputBase-root': {
      border: `1px solid ${color.primary.main}`,
      backgroundColor: 'transparent !important'
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      fontSize: '14px !important'
    }
  },
  submit: {
    backgroundColor: `${color.primary.main}`,
    color: 'white',
    transition: '0.25s',
    marginTop: '5px',
    '&:hover': {
      transform: 'translateY(-0.1em)'
    }
  },
  link: {
    color: `${color.primary.main}`,
    fontWeight: 600,
    fontSize: '12px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  mt: {
    marginTop: '8px'
  },
  alignRight: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
    ' & >div': {
      width: '100%'
    }
  },
  summary: {
    marginTop: '10px'
  }
}));
