import { Box, Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewTable, execConfirmModal } from 'shared';
import { usePredictionStyles } from './prediction.styles';
import { usePredictionBuildTable } from './usePredictionBuildTable';

export const Prediction = ({ prediction, simulation, setOpen, cleanPrediction }) => {
  const classes = usePredictionStyles();
  const [showPrediction, setShowPrediction] = useState(false);
  const { t } = useTranslation(NAME_SPACE.PREDICTIONS);
  const { headCells, rows } = usePredictionBuildTable({ prediction, t });

  const confirmValidation = useCallback(() => {
    setShowPrediction(true);
    setOpen(false);
  }, [setOpen]);

  const showModal = useCallback(() => {
    execConfirmModal({
      title: t('validations.pecletTooSmallTitle'),
      description: t('validations.pecletTooSmallDescription'),
      confirmText: t('common:actions.yes'),
      declineText: t('common:actions.no'),
      onConfirm: confirmValidation,
      onCancel: cleanPrediction
    });
  }, [t, confirmValidation, cleanPrediction]);

  useEffect(() => {
    if (!prediction.validations) {
      setShowPrediction(true);
    } else {
      showModal();
    }
  }, [prediction, t]);

  return (
    <>
      {showPrediction && (
        <Grid container className={classes.container}>
          <p className={classes.title}>
            {simulation.name} {t('results')}
          </p>
          {prediction.graphics.map(m => (
            <Box key={m} component="img" alt={m} src={`data:image/jpeg;base64,${m}`} />
          ))}
          <ViewTable
            rows={rows}
            cells={headCells}
            defaultOrderBy={'texp'}
            totalRows={rows?.length}
            serverSidePaging
            allowRowFilter={false}
            allowRowChecking={false}
            disableOrderBy={true}
          />
        </Grid>
      )}
    </>
  );
};
