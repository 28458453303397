import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringHelper } from 'shared/helper';

export function useHandleRequest() {
  const [errorInfo, setErrorInfo] = useState({ message: '', detail: '' });
  const { t } = useTranslation();

  function splitAndTranslateEach(value = '') {
    if (value.includes('/nl')) {
      return value.split('/nl').map(item => `- ${t(item)}`);
    }
    return t(value);
  }

  const request = useCallback(
    async (requestCallback, throwError = true, onErrorCallback) => {
      setErrorInfo({ message: '', detail: '' });
      try {
        return await requestCallback();
      } catch (error) {
        let errorMsg;
        if (error?.response?.data) {
          errorMsg = JSON.parse(
            error.response ? (error.response.data ? error.response.data : error.message) : error.message
          );
        } else {
          errorMsg = { message: error.message, detail: '' };
        }

        const errorDetail = errorMsg.detail ? stringHelper.normalizeQuotes(errorMsg.detail) : '';

        setErrorInfo({
          message: t(stringHelper.normalizeQuotes(errorMsg.message)),
          detail: splitAndTranslateEach(stringHelper.normalizeQuotes(errorDetail))
        });
        onErrorCallback && onErrorCallback();
        if (throwError) {
          throw new Error(errorMsg.message);
        }

        return error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return { request, errorInfo, setErrorInfo };
}
