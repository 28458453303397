import { useState } from "react";

export function useRequestParams() {
  const [paging, setPaging] = useState();
  const [sorting, setSorting] = useState();
  const [filteredCells, setFilteredCells] = useState();
  const [advancedFilters, setAdvancedFilters] = useState();

  const setRequestParams = (
    paging,
    sorting,
    filteredCells,
    advancedFilters
  ) => {
    setPaging(paging);
    setSorting(sorting);
    setFilteredCells(filteredCells);
    setAdvancedFilters(advancedFilters);
  };

  const currentRequestParams = {
    paging,
    sorting,
    filteredCells,
    advancedFilters,
  };

  return {
    paging,
    sorting,
    filteredCells,
    advancedFilters,
    currentRequestParams,
    setRequestParams,
  };
}
