export const color = {
  basic: {
    brightest: 'rgba(219, 222, 252, 0.55)',
    brighter: '#B9C6DA',
    bright: '#c2c5ca',
    normal: '#8A9AAF',
    semidark: '#4A566D',
    dark: '#14142A',
    darker: '#000000'
  },
  primary: {
    dark: '#7E1717',
    light: '#f8ecef',
    main: '#a5082e',
    lightest: '#f8ecef'
  },
  secondary: {
    dark: '#a5082e',
    light: '#f8ecef',
    main: '#f8ecef',
    lightest: '#fb6060'
  },
  action: {
    error: '#f44336',
    activeButton: '#a5082e',
    delete: {
      main: '#ff4c4c',
      light: '#eed5e3'
    },
    add: {
      main: '#34bf49',
      light: '#d0e6e3'
    }
  },
  toast: {
    error: '#f76e65',
    warn: '#F9A825',
    info: {
      background: '#e2e3e5',
      font: '#7f8790'
    }
  },
  table: {
    header: 'rgb(243, 242, 247)',
    summary: 'rgb(243, 242, 247)'
  },
  buttons: {
    disabled: {
      background: 'transparent',
      text: '#838383',
      border: '#838383'
    }
  }
};
