import { http } from './http';

class Auth {
  login(user) {
    const config = {
      disableErrorToasters: true
    };
    return http.post('/authentication/token', user, config, null, false);
  }

  signup(user) {
    const config = {
      disableErrorToasters: true
    };
    return http.post('/users', user, config, null, false);
  }

  refreshToken(refreshToken) {
    return http.post('/authentication/refresh-token', { refreshToken }, {}, null, false);
  }

  logout(refreshToken) {
    const config = {
      disableErrorToasters: true
    };
    return http.post('/authentication/revoke-token', { refreshToken }, config, null, false);
  }

  changePassword(request) {
    return http.post('/authentication/change-password', request, {});
  }

  forgotPassword(email) {
    const config = {
      disableErrorToasters: true
    };
    return http.post('/authentication/forgot-password', { email }, config);
  }

  async checkTokenValidity(email, token) {
    const config = {
      disableErrorToasters: true
    };
    let result = await http.post('/authentication/check-token-password', { email, token }, config);
    return result && result.data;
  }

  resetPassword(email, token, newPassword) {
    console.log(email, token, newPassword);
    return http.post('/authentication/reset-password', { email, token, newPassword }, {});
  }

  confirmEmail(queryString) {
    return http.get(`/authentication/confirm-email${queryString}`, {});
  }
}

export const auth = new Auth();
