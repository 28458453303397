import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { App } from 'App';
import { APIS } from 'app.apis.const';
import { AuthProvider } from 'auth/useAuth';
import { LoadingProvider } from 'loading';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { http } from 'services/http';
import { KEY_SETTINGS, settings } from 'settings';
import './i18n';
import reportWebVitals from './reportWebVitals';
import theme from './styles';

async function startUp() {
  await settings.loadSettings();

  const mathcolApiURL = `${settings.getSetting(KEY_SETTINGS.API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.API_VERSION
  )}`;

  const predictionsApiURL = `${settings.getSetting(KEY_SETTINGS.PREDICTIONS_API_URL)}/api/${settings.getSetting(
    KEY_SETTINGS.PREDICTIONS_API_VERSION
  )}`;

  http.setBaseUrls([
    { key: APIS.MATCHOL, url: mathcolApiURL },
    { key: APIS.PREDICTIONS, url: predictionsApiURL }
  ]);
}

(async () => {
  try {
    await startUp();
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <LoadingProvider>
                <CssBaseline />
                <App />
              </LoadingProvider>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root')
    );
  } catch (error) {
    ReactDOM.render(
      <React.StrictMode>
        <dir>{error}</dir>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
