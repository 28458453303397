import { useCallback, useState } from 'react';
import { simulations } from 'services';
import { objectHelper, useHandleRequest } from 'shared';

export function useSimulation({ t }) {
  const initialState = {
    lb: '',
    db: '',
    d: '',
    q: '',
    e: '',
    rhoa: '',
    qm: '',
    qeexp: '',
    ci: '',
    t12: '',
    ctExps: []
  };

  const [simulationData, setSimulationData] = useState(initialState);
  const { request, errorInfo, setErrorInfo } = useHandleRequest();

  async function getData(id) {
    await request(async () => {
      const response = await simulations.getById(id);
      if (response) {
        setSimulationData(response.data);
      }
    }, false);
  }

  const loadSimulation = id => {
    getData(id);
  };

  const saveSimulation = useCallback(
    async inputs => {
      return await request(
        async () => {
          let response = await simulations.create({ ...simulationData, ...inputs });
          setSimulationData(response.data);
          return response.data.id;
        },
        true,
        () => setSimulationData(simulationData)
      );
    },
    [request, simulationData]
  );

  const cleanSimulation = () => {
    setSimulationData(initialState);
  };

  const onSaveValidations = () => {
    const copySimulation = objectHelper.deepClone(simulationData);
    delete copySimulation.description;
    if (copySimulation.ctExps.length === 0) {
      setErrorInfo({ message: t('validations.tableEmpty') });
    } else {
      setErrorInfo({ message: '', detail: '' });
    }
    return objectHelper.isEmpty(copySimulation);
  };

  return {
    simulation: simulationData,
    errorInfo,
    setSimulationData,
    saveSimulation,
    cleanSimulation,
    onSaveValidations,
    loadSimulation
  };
}
