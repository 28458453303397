import { makeStyles } from '@material-ui/core';

export const useSimulationStyles = makeStyles(theme => ({
  filterDate: {
    paddingTop: '16px'
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem',
      '& .MuiDialogContent-root': {
        overflow: 'hidden',
        '& .viewtable': {
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          maxHeight: '75vh',
          gap: '10px'
        }
      }
    }
  }
}));
