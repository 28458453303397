import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export function NumberFormatCustom({ inputRef, onChange, name, decimalScale, ...other }) {
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      placeholder={`0`}
      isNumericString
      allowNegative={false}
      //thousandSeparator={"."} idk
      decimalSeparator={','}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number
};
