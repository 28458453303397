import { ROUTES } from 'app.routes.const';
import { useAuth } from 'auth/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

export const PrivateLayout = () => {
  const { isLogged } = useAuth();
  return isLogged ? (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  ) : (
    <Navigate to={ROUTES.AUTH.LOGIN} />
  );
};
