import moment from "moment";

function validateBeforeExecute(string, cb) {
  if (typeof string === "string") {
    return cb();
  } else {
    throw new Error(`string required, ${typeof string} found`);
  }
}

function toShortDate(string) {
  return validateBeforeExecute(string, () =>
    moment(string).format("DD/MM/YYYY")
  );
}

export const dateHelper = {
  toShortDate,
};
