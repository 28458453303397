import axios from 'axios';

export const KEY_SETTINGS = {
  API_URL: 'API_URL',
  API_VERSION: 'API_VERSION',
  PREDICTIONS_API_URL: 'PREDICTIONS_API_URL',
  PREDICTIONS_API_VERSION: 'PREDICTIONS_API_VERSION',
  ENVIRONMENT: 'ENVIRONMENT',
  CREDENTIALS: 'CREDENTIALS'
};

class Settings {
  async loadSettings() {
    const isLocalHost = window.location.hostname.includes('localhost');
    const $SETTINGS_PATH = '/config/settings.json';
    const $HOST = isLocalHost ? window.location.host : window.location.hostname;
    const $URL_CONFIG = `${window.location.protocol}//${$HOST}${$SETTINGS_PATH}`;

    try {
      const { data } = await axios.get($URL_CONFIG);
      Object.keys(data).forEach(settingName => localStorage.setItem(settingName, data[settingName]));
    } catch (error) {
      throw error || 'Api error';
    }
  }

  getSetting(settingName) {
    return localStorage.getItem(settingName);
  }
}

export const settings = new Settings();
