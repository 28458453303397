import { makeStyles } from '@material-ui/core';

export const useHelpStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem'
    }
  },
  img: {
    width: '100%'
  },
  littleImg: {
    width: '50%'
  },
  title: {
    fontWeight: 700
  },
  tab: {
    paddingLeft: '30px'
  },
  containerImg: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));
