import { useCallback, useEffect, useState } from "react";
import { objectHelper } from "shared/helper";

export function useStateWithBackup() {
  const [state, setState] = useState();
  const [stateBackup, setStateBackup] = useState();

  useEffect(() => {
    if (state) {
      setStateBackup(objectHelper.deepClone(state));
    }
  }, [state]);

  const rollBack = useCallback(() => {
    setState(objectHelper.deepClone(stateBackup));
  }, [setState, stateBackup]);

  return [state, setState, rollBack];
}
