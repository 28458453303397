import { ErrorSummary } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonAdd,
  ButtonDelete,
  ButtonPrimary,
  EditModal,
  ViewTable,
  useCheckedElements,
  useSelectedElement
} from 'shared';
import { arrayHelper } from 'shared/helper/array.helper';
import { v4 as uuidv4 } from 'uuid';
import { FormImport } from './FormImport';
import { FormTableSimulation } from './FormSimulationTable';
import { useSimulationTableStyles } from './simulationTable.styles';
import { useDataBuildTable } from './useDataBuildTable';

const SimulationTable = ({ simulation, setSimulationData, errorInfo }) => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const classes = useSimulationTableStyles();

  const deleteData = dataToDelete => {
    const filtered = simulation.ctExps.filter(function (obj) {
      return !dataToDelete.some(function (ctDeleted) {
        return obj.id === ctDeleted.id;
      });
    });
    setSimulationData(prevInputs => ({
      ...prevInputs,
      ctExps: filtered
    }));
  };

  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    onCheckElement,
    onCheckAllElements,
    isChecked,
    isAnyChecked,
    checkedElementsCount
  } = useCheckedElements({
    t,
    mainKey: 'texp',
    prefix: 'table',
    action: deleteData
  });

  const { selectedElement: selectedCt_exp, handleNew, handleUnselect } = useSelectedElement();
  const {
    selectedElement: selectedImport,
    handleNew: handleNewImport,
    handleUnselect: handleUnselectImport
  } = useSelectedElement();
  const { rows, headCells } = useDataBuildTable({ simulation, t });

  async function handleSubmitFormCt_exp(inputs) {
    setSimulationData(prevInputs => ({
      ...prevInputs,
      ctExps: prevInputs.ctExps
        ? arrayHelper.sortByKeyAsc(prevInputs.ctExps.concat({ ...inputs, id: uuidv4() }), 'texp')
        : arrayHelper.sortByKeyAsc([{ ...inputs, id: uuidv4() }], 'texp')
    }));
    return true;
  }

  function handleSubmitImport(inputs) {
    setSimulationData(prevInputs => ({
      ...prevInputs,
      ctExps: arrayHelper.sortByKeyAsc(prevInputs.ctExps.concat(inputs.map(i => ({ ...i, id: uuidv4() }))), 'texp')
    }));
    return true;
  }

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.btnContainer}>
        <ButtonDelete
          elementsToDelete={checkedElements}
          confirmDescription={getDeleteDescription}
          onConfirm={handleCheckedElements}
        />
        <ButtonAdd onClick={handleNew} disabled={isAnyChecked} />
        <ButtonPrimary onClick={handleNewImport}>
          <PublishIcon />
          {t('common:import.import')}
        </ButtonPrimary>
      </Grid>
      {errorInfo?.message && !rows.length && (
        <Grid item xs={12} className={classes.error}>
          <ErrorSummary text={errorInfo?.message} detail={errorInfo?.detail} />
        </Grid>
      )}

      <ViewTable
        rows={rows}
        cells={headCells}
        totalRows={rows.length}
        onCheckElement={onCheckElement}
        onCheckAllElements={onCheckAllElements}
        checkedElementsCount={checkedElementsCount}
        isAnyChecked={isAnyChecked}
        isChecked={isChecked}
        allowRowFilter={false}
        rowsPerPage={10}
      />
      <EditModal
        entity={selectedCt_exp}
        handleCloseEditModal={handleUnselect}
        onSubmitEditModal={handleSubmitFormCt_exp}
        onKeepCreating={handleNew}
        className={classes.modal}
      >
        <FormTableSimulation ct_exp={selectedCt_exp} />
      </EditModal>
      <EditModal
        entity={selectedImport}
        title={t('common:import.dialogTitle')}
        handleCloseEditModal={handleUnselectImport}
        onSubmitEditModal={handleSubmitImport}
        onKeepCreating={handleNewImport}
        className={classes.modal}
        btnKeepCreating={false}
      >
        <FormImport />
      </EditModal>
    </Grid>
  );
};

export default SimulationTable;
