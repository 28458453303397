import { Button } from '@material-ui/core';
import React from 'react';
import { useButtonLinkStyles } from './buttonLink.styles';

export const ButtonLink = ({ children, className, ...props }) => {
  const classes = useButtonLinkStyles();
  return (
    <Button className={`${className} ${classes.button}`} {...props}>
      {children}
    </Button>
  );
};
