export const resources = {
  en: {
    common: require('./en/common.json'),
    simulations: require('./en/simulations.json'),
    predictions: require('./en/predictions.json'),
    help: require('./en/help.json'),
    about: require('./en/about.json'),
    authentication: require('./en/authentication.json'),
    users: require('./en/users.json')
  },
  es: {
    common: require('./es/common.json'),
    simulations: require('./es/simulations.json'),
    predictions: require('./es/predictions.json'),
    help: require('./es/help.json'),
    about: require('./es/about.json'),
    authentication: require('./es/authentication.json'),
    users: require('./es/users.json')
  },
  ca: {
    common: require('./ca/common.json'),
    simulations: require('./ca/simulations.json'),
    predictions: require('./ca/predictions.json'),
    help: require('./ca/help.json'),
    about: require('./ca/about.json'),
    authentication: require('./ca/authentication.json'),
    users: require('./ca/users.json')
  }
};
