import { ErrorSummary, Form, Input, SuccessSummary } from '@engloba-tech/englobity';
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from '@material-ui/core';

import { KeyboardArrowLeft, Visibility, VisibilityOff } from '@material-ui/icons';
import { ROUTES } from 'app.routes.const';
import { useAuth } from 'auth/useAuth';
import { NAME_SPACE } from 'i18n';
import { useLoading } from 'loading';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, useRequestLoading } from 'shared';
import { useAuthStyles } from '../auth.styles';

export const Signup = () => {
  const classes = useAuthStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const { handleLoading } = useLoading();
  const { signup, errorInfo } = useAuth();
  const { callbackRequest } = useRequestLoading();
  const [inputs, setInputs] = useState({});
  const [successsSignup, setSuccessSignup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword(showRepeatPassword => !showRepeatPassword);

  const handleField = ({ target }) => setInputs(prevInputs => ({ ...prevInputs, [target.name]: target.value }));

  const goLogin = useCallback(() => {
    setTimeout(() => {
      navigate(ROUTES.AUTH.LOGIN);
    }, 3500);
  }, [navigate]);

  async function handleLogin() {
    handleLoading(
      async () =>
        await callbackRequest(async () => {
          setSuccessSignup(false);
          const response = await signup(inputs);
          console.log(response);
          if (response) {
            setSuccessSignup(true);
            goLogin();
          }
        })
    );
  }
  return (
    <>
      <Typography variant="h5" component="h4" className={classes.title}>
        {t('signup.title')}
      </Typography>
      <Form onSubmit={handleLogin} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              type="email"
              required
              className={classes.input}
              name="email"
              label={t('signup.properties.email')}
              variant="standard"
              value={inputs?.email || ''}
              onChange={e => handleField(e)}
              inputProps={{ 'aria-label': 'email' }}
              validators={['required', 'isEmail']}
              errorMessages={[t('common:validations.required'), t('common:validations.isEmail')]}
              InputProps={{
                disableUnderline: true
              }}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              arrow
              title={<>{t('resetPassword.validations.passwordPolicy')}</>}
            >
              <Input
                className={classes.input}
                required
                type={showPassword ? 'text' : 'password'}
                name="password"
                label={t('signup.properties.password')}
                variant="standard"
                value={inputs?.password || ''}
                onChange={e => handleField(e)}
                inputProps={{ 'aria-label': t('signup.properties.password') }}
                validators={['required']}
                errorMessages={[t('common:validations.required')]}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              arrow
              title={<>{t('resetPassword.validations.passwordPolicy')}</>}
            >
              <Input
                className={classes.input}
                required
                type={showRepeatPassword ? 'text' : 'password'}
                name="repeatPassword"
                label={t('signup.properties.repeatPassword')}
                variant="standard"
                value={inputs?.repeatPassword || ''}
                onChange={e => handleField(e)}
                inputProps={{ 'aria-label': t('signup.properties.repeatPassword') }}
                validators={['required', `isPasswordMatch:${inputs.password}`]}
                errorMessages={[t('common:validations.required'), t('resetPassword.validations.passwordMissmatch')]}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowRepeatPassword}>
                        {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <ButtonPrimary type="submit" color="primary" className={classes.submit}>
              {t('signup.properties.submit')}
            </ButtonPrimary>
          </Grid>
          {successsSignup && (
            <Grid item xs={12}>
              <SuccessSummary text={t('signup.success')} />
            </Grid>
          )}
          {errorInfo && (
            <Grid item xs={12}>
              <ErrorSummary text={t(errorInfo.message)} className={classes.summary} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography onClick={() => navigate(ROUTES.AUTH.LOGIN)} className={classes.link}>
              <KeyboardArrowLeft />
              {t('forgotPassword.goBack')}
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
