import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useFormImportStyles = makeStyles(theme => ({
  error: {
    marginBottom: '1rem'
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '0.9em',
    fontFamily: 'sans-serif',
    minWidth: '400px',
    width: '100%',
    marginBottom: '10px',
    paddingBottom: '10px',

    '& thead tr': {
      backgroundColor: `${color.secondary.light}`,
      textAlign: 'left'
    },
    '& thead th': {
      padding: '10px',
      border: '2px solid white'
    },
    '& tbody tr td': {
      padding: '5px 10px 5px 10px'
    },
    '& tbody': {
      marginBottom: '1rem'
    },
    '& tbody tr': {
      borderBottom: `1px solid ${color.secondary.light}`
    }
  },
  tableContainer: {
    overflow: 'auto',
    maxHeight: '500px'
  }
}));
