import { ErrorSummary, Form, Input } from '@engloba-tech/englobity';
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ROUTES } from 'app.routes.const';
import { useAuth } from 'auth/useAuth';
import { NAME_SPACE } from 'i18n';
import { useLoading } from 'loading';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary, useRequestLoading } from 'shared';
import { useAuthStyles } from '../auth.styles';

export const Login = () => {
  const classes = useAuthStyles();
  const navigate = useNavigate();
  const { handleLoading } = useLoading();
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const { login, errorInfo } = useAuth();
  const { callbackRequest } = useRequestLoading();
  const [inputs, setInputs] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleField = ({ target }) => setInputs(prevInputs => ({ ...prevInputs, [target.name]: target.value }));

  const enterLogin = useCallback(() => {
    navigate(ROUTES.HOME);
  }, [navigate]);

  async function handleLogin() {
    await callbackRequest(async () => {
      handleLoading(async () => await login(inputs));
      enterLogin();
    });
  }

  return (
    <>
      <Typography variant="h5" component="h4" className={classes.title}>
        {t('login.title')}
      </Typography>
      <Form onSubmit={handleLogin} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              type="email"
              required
              className={classes.input}
              name="user"
              label={t('login.properties.email')}
              variant="standard"
              value={inputs?.user || ''}
              onChange={e => handleField(e)}
              inputProps={{ 'aria-label': 'email' }}
              validators={['required', 'isEmail']}
              errorMessages={[t('common:validations.required'), t('common:validations.isEmail')]}
              InputProps={{
                disableUnderline: true
              }}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12} className={classes.alignRight}>
            <Input
              className={classes.input}
              required
              type={showPassword ? 'text' : 'password'}
              name="password"
              label={t('login.properties.password')}
              variant="standard"
              value={inputs?.password || ''}
              onChange={e => handleField(e)}
              inputProps={{ 'aria-label': t('properties.q') }}
              validators={['required']}
              errorMessages={[t('common:validations.required')]}
              autoComplete="current-password"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography onClick={() => navigate(ROUTES.AUTH.FORGOT_PASSWORD)} className={classes.link}>
              {t('forgotPassword.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonPrimary type="submit" color="primary" className={classes.submit}>
              {t('login.title')}
            </ButtonPrimary>
          </Grid>
          {errorInfo && (
            <Grid item xs={12}>
              <ErrorSummary text={t(errorInfo.message)} className={classes.summary} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography onClick={() => navigate(ROUTES.AUTH.SIGNUP)} className={classes.link}>
              {t('signup.notAnAccount')}
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
