import { makeStyles } from '@material-ui/core/styles';

export const useEditModalStyles = makeStyles(theme => ({
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '50rem',
      '& .MuiGrid-item': {
        '& .MuiSnackbar-root': {
          marginBottom: 0
        }
      },
      '& .MuiSnackbar-root': {
        marginBottom: theme.spacing(3)
      }
    }
  }
}));
