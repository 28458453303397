import { makeStyles } from '@material-ui/core';
import { color } from '../../styles/color.styles';

export const useHeaderStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 20rem 2rem 20rem'
  },
  svg: {
    display: 'flex',
    cursor: 'pointer',
    height: '2rem',
    width: 'auto'
  },
  btnLang: {
    padding: '0'
  },
  activeBtn: {
    color: `${color.primary.main}`,
    fontWeight: 800
  },
  tooltip: {
    borderRadius: '4px'
  },
  box: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center'
  },
  icon: {
    cursor: 'pointer',
    color: color.primary.main
  }
}));
