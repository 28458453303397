const AUTH_STORAGE = {
  PATH_TO_GO: 'path_to_go',
  TOKEN: 'TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN'
};

class AuthStorage {
  setToken(token) {
    localStorage.setItem(AUTH_STORAGE.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(AUTH_STORAGE.TOKEN);
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem(AUTH_STORAGE.REFRESH_TOKEN, refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem(AUTH_STORAGE.REFRESH_TOKEN);
  }
}

export const authStorage = new AuthStorage();
