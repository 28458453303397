import { Box, SvgIcon, Toolbar, Tooltip } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ROUTES } from 'app.routes.const';
import { ReactComponent as MathColLogo } from 'assets/images/logox2.svg';
import { useAuth } from 'auth/useAuth';
import i18n, { LANGUAGES, langSelector } from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { http } from 'services/http';
import { ButtonPrimary } from 'shared';
import { useHeaderStyles } from './Header.styles';

export const Header = () => {
  const navigate = useNavigate();
  const classes = useHeaderStyles();
  const [lang, setLang] = useState(langSelector());
  const { logout } = useAuth();

  const onChangeLang = lang_code => {
    i18n.changeLanguage(lang_code);
    setLang(lang_code);
    http.setAcceptLanguage(lang_code);
  };

  return (
    <Toolbar className={classes.toolbar}>
      <SvgIcon
        component={MathColLogo}
        viewBox="0 0 390 90"
        onClick={() => navigate(ROUTES.HOME)}
        className={classes.svg}
      />
      <Box className={classes.box}>
        <Box>
          {LANGUAGES.map(({ code, label }) => (
            <ButtonPrimary
              size="small"
              className={code === lang ? `${classes.activeBtn} ${classes.btnLang}` : `${classes.btnLang}`}
              variant="contained"
              key={code}
              onClick={() => onChangeLang(code)}
            >
              {label}
            </ButtonPrimary>
          ))}
        </Box>
        <Tooltip title="Cerrar sesión" placement="start-top" classes={{ tooltip: classes.tooltip }}>
          <ExitToAppIcon onClick={logout} className={classes.icon} />
        </Tooltip>
      </Box>
    </Toolbar>
  );
};
