import { makeStyles } from '@material-ui/core/styles';

export const useLoadingStyles = makeStyles(theme => ({
  loader: {
    position: 'fixed',
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.6)',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  overflowHidden: {
    overflow: 'hidden'
  }
}));
