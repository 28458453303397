import { Form } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from 'shared';

import { NAME_SPACE } from 'i18n';
import { Prediction } from './Prediction';
import { SimulationForm } from './Simulation';
import SimulationTable from './Simulation/Table/SimulationTable';
import { useResultStyles } from './result.styles';

export const Result = ({ formRef, simulation, setSimulationData, errorInfo, prediction, cleanPrediction }) => {
  const [open, setOpen] = useState(true);
  const classes = useResultStyles();
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);

  useEffect(() => {
    if (prediction && !prediction.validations) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [prediction]);

  return (
    <>
      <Form className={classes.form} elementRef={formRef} autoComplete="off">
        {simulation.name && simulation.id && (
          <Grid container className={classes.info}>
            <p className={classes.title}>
              {simulation.name} {t('simulation')}
            </p>
            {simulation?.description && <p className={classes.description}>{simulation?.description}</p>}
          </Grid>
        )}
        {open && (
          <>
            <SimulationForm {...{ simulation, setSimulationData }} />
            <SimulationTable {...{ simulation, setSimulationData, errorInfo }} />
          </>
        )}
        {prediction && (
          <Grid container className={classes.container}>
            <ButtonLink onClick={() => setOpen(!open)}>
              {open ? t('common:actions.viewLess') : t('common:actions.viewMore')}
            </ButtonLink>
          </Grid>
        )}
      </Form>
      {prediction && <Prediction {...{ prediction, simulation, setOpen, cleanPrediction }} />}
    </>
  );
};
