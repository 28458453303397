import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useButtonLinkStyles = makeStyles(theme => ({
  button: {
    boxShadow: 'none',
    background: 'transparent',
    textTransform: 'none',
    textDecoration: 'underline',
    fontWeight: 600,
    border: 'none',
    color: `${color.primary.main}`,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    fontSize: '14px',
    height: '100%',
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
      color: `${color.basic.darker}`
    }
  }
}));
