import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDelete, EditModal, ViewTable, useCheckedElements } from 'shared';
import { useSimulationStyles } from './useSimulation.styles';
import { useSimulationsBuildTable } from './useSimulationsBuildTable';
import { useSimulationsPaginated } from './useSimulationsPaginated';

export const SimulationList = ({
  selectedSimulation,
  handleLoadUnselect,
  handleSubmitSave,
  loadSimulation,
  cleanPrediction
}) => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const classes = useSimulationStyles();
  const { simulations, getSimulations, deleteSimulations, handleCloseEditModal } = useSimulationsPaginated();

  const {
    checkedElements,
    getDeleteDescription,
    handleCheckedElements,
    onCheckElement,
    isAnyChecked,
    onCheckAllElements,
    checkedElementsCount,
    isChecked,
    setCheckedElements
  } = useCheckedElements({
    t,
    mainKey: 'name',
    action: deleteSimulations
  });

  const { rows, headCells } = useSimulationsBuildTable({
    simulations,
    loadSimulation,
    handleLoadUnselect,
    cleanPrediction,
    t
  });

  const handleClose = () => {
    handleCloseEditModal();
    handleLoadUnselect();
    setCheckedElements([]);
  };
  return (
    <EditModal
      className={classes.modal}
      entity={selectedSimulation}
      handleCloseEditModal={handleClose}
      onSubmitEditModal={handleSubmitSave}
      btnKeepCreating={false}
      title={t('listTitle')}
      showDefaultButtons={false}
      readonly
      customButtons={
        <>
          <ButtonDelete
            elementsToDelete={checkedElements}
            confirmDescription={getDeleteDescription}
            onConfirm={handleCheckedElements}
          />
        </>
      }
    >
      <ViewTable
        rows={rows}
        cells={headCells}
        onFetchData={getSimulations}
        totalRows={simulations?.total ?? 0}
        serverSidePaging
        onCheckElement={onCheckElement}
        onCheckAllElements={onCheckAllElements}
        checkedElementsCount={checkedElementsCount}
        isAnyChecked={isAnyChecked}
        isChecked={isChecked}
        defaultOrderBy={{ property: 'name', direction: 'asc' }}
      />
    </EditModal>
  );
};
