import { Grid } from '@material-ui/core';
import i18n, { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import add_ca from '../../../assets/images/add_ca.jpg';
import add_en from '../../../assets/images/add_en.jpg';
import add_es from '../../../assets/images/add_es.jpg';
import delete_ca from '../../../assets/images/delete_ca.jpg';
import delete_en from '../../../assets/images/delete_en.jpg';
import delete_es from '../../../assets/images/delete_es.jpg';
import delete_sim_ca from '../../../assets/images/delete_sim_ca.jpg';
import delete_sim_en from '../../../assets/images/delete_sim_en.jpg';
import delete_sim_es from '../../../assets/images/delete_sim_es.jpg';
import execute from '../../../assets/images/execute.jpg';
import execute2 from '../../../assets/images/execute2.jpg';
import filter_ca from '../../../assets/images/filter_ca.jpg';
import filter_en from '../../../assets/images/filter_en.jpg';
import filter_es from '../../../assets/images/filter_es.jpg';
import import_ca from '../../../assets/images/import_ca.jpg';
import import_en from '../../../assets/images/import_en.jpg';
import import_es from '../../../assets/images/import_es.jpg';
import lang_ca from '../../../assets/images/lang_ca.jpg';
import lang_en from '../../../assets/images/lang_en.jpg';
import lang_es from '../../../assets/images/lang_es.jpg';
import new_ca from '../../../assets/images/new_ca.jpg';
import new_en from '../../../assets/images/new_en.jpg';
import new_es from '../../../assets/images/new_es.jpg';
import open2_ca from '../../../assets/images/open2_ca.jpg';
import open2_en from '../../../assets/images/open2_en.jpg';
import open2_es from '../../../assets/images/open2_es.jpg';
import open_ca from '../../../assets/images/open_ca.jpg';
import open_en from '../../../assets/images/open_en.jpg';
import open_es from '../../../assets/images/open_es.jpg';
import save2_ca from '../../../assets/images/save2_ca.jpg';
import save2_en from '../../../assets/images/save2_en.jpg';
import save2_es from '../../../assets/images/save2_es.jpg';
import save_ca from '../../../assets/images/save_ca.jpg';
import save_en from '../../../assets/images/save_en.jpg';
import save_es from '../../../assets/images/save_es.jpg';
import table_ca from '../../../assets/images/table_ca.jpg';
import table_en from '../../../assets/images/table_en.jpg';
import { default as table_es } from '../../../assets/images/table_es.jpg';
import { useHelpStyles } from './help.styles';

export const Help = () => {
  const { t } = useTranslation(NAME_SPACE.HELP);
  const classes = useHelpStyles();

  const TABLE_IMAGES = {
    'ca-CA': table_ca,
    'es-ES': table_es,
    'en-EN': table_en
  };
  const ADD_IMAGES = {
    'ca-CA': add_ca,
    'es-ES': add_es,
    'en-EN': add_en
  };

  const IMPORT_IMAGES = {
    'ca-CA': import_ca,
    'es-ES': import_es,
    'en-EN': import_en
  };

  const DELETE = {
    'ca-CA': delete_ca,
    'es-ES': delete_es,
    'en-EN': delete_en
  };

  const SAVE = {
    'ca-CA': save_ca,
    'es-ES': save_es,
    'en-EN': save_en
  };

  const SAVE2 = {
    'ca-CA': save2_ca,
    'es-ES': save2_es,
    'en-EN': save2_en
  };

  const OPEN = {
    'ca-CA': open_ca,
    'es-ES': open_es,
    'en-EN': open_en
  };
  const OPEN2 = {
    'ca-CA': open2_ca,
    'es-ES': open2_es,
    'en-EN': open2_en
  };

  const FILTER = {
    'ca-CA': filter_ca,
    'es-ES': filter_es,
    'en-EN': filter_en
  };

  const DELETE_SIM = {
    'ca-CA': delete_sim_ca,
    'es-ES': delete_sim_es,
    'en-EN': delete_sim_en
  };

  const NEW = {
    'ca-CA': new_ca,
    'es-ES': new_es,
    'en-EN': new_en
  };

  const LANG = {
    'ca-CA': lang_ca,
    'es-ES': lang_es,
    'en-EN': lang_en
  };

  const languageSelected = i18n.language;
  return (
    <Grid>
      <p>{t('description')}</p>
      <p className={classes.title}>{t('titles.createExecution')}</p>
      <p>{t('descriptions.createExecution')}</p>
      <img src={TABLE_IMAGES[languageSelected]} alt="table" className={classes.img} />
      <p>{t('descriptions.createExecution1')}</p>
      <p className={classes.tab}>{t('descriptions.createExecution2')}</p>
      <div className={classes.containerImg}>
        <img src={ADD_IMAGES[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p className={classes.tab}>{t('descriptions.createExecution3')}</p>
      <div className={classes.containerImg}>
        <img src={IMPORT_IMAGES[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p>{t('descriptions.createExecution4')}</p>
      <img src={DELETE[languageSelected]} alt="table" className={classes.img} />
      <p>{t('descriptions.createExecution5')}</p>
      <img src={execute2} alt="table" className={classes.img} />
      <img src={execute} alt="table" className={classes.img} />
      <p className={classes.title}>{t('titles.saveExecution')}</p>
      <p>{t('descriptions.saveExecution')}</p>
      <img src={SAVE[languageSelected]} alt="table" className={classes.img} />
      <p>{t('descriptions.saveExecution1')}</p>
      <div className={classes.containerImg}>
        <img src={SAVE2[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p className={classes.title}>{t('titles.openExecution')}</p>
      <img src={OPEN[languageSelected]} alt="table" className={classes.img} />
      <p>{t('descriptions.openExecution1')}</p>
      <div className={classes.containerImg}>
        <img src={OPEN2[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p>{t('descriptions.openExecution2')}</p>
      <div className={classes.containerImg}>
        <img src={FILTER[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p>{t('descriptions.openExecution3')}</p>
      <div className={classes.containerImg}>
        <img src={DELETE_SIM[languageSelected]} alt="table" className={classes.littleImg} />
      </div>
      <p className={classes.title}>{t('titles.cleanExecution')}</p>
      <p>{t('descriptions.cleanExecution')}</p>
      <img src={NEW[languageSelected]} alt="table" className={classes.img} />
      <p className={classes.title}>{t('titles.language')}</p>
      <p>{t('descriptions.language')}</p>
      <img src={LANG[languageSelected]} alt="table" className={classes.img} />
    </Grid>
  );
};
