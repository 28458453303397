import { color } from './color.styles';

export const globals = {
  html: {
    fontSize: '16px', // 1rem = 16px,
    height: '100%'
  },
  body: {
    '& *': {
      fontFamily: 'Montserrat'
    },
    fontFamily: 'Montserrat',
    fontWeight: '500',
    height: '100%',
    backgroundColor: 'white'
  },
  '.MuiTooltip-popper': {
    '& .MuiTooltip-tooltip': {
      fontSize: '0.875rem !important',
      fontFamily: 'Montserrat !important'
    },
    zIndex: '2300 !important'
  },
  '.MuiDialog-root': {
    zIndex: '2200 !important'
  },
  '.MuiAutocomplete-popper': {
    zIndex: '2300 !important'
  },
  '.MuiPopover-root': {
    zIndex: '2300 !important'
  },
  '.MuiInputLabel-formControl': {
    zIndex: 1
  },
  '& .MuiFormLabel-root ': {
    fontWeight: '600 !important',
    fontSize: '15px !important'
  },
  '.MuiFormControl-root': {
    display: 'flex !important',
    flexDirection: 'column'
  },
  '.MuiInputBase-root': {
    margingTop: '10px !important',
    backgroundColor: `${color.primary.lightest}`,
    fontSize: 'inherit',
    '& .MuiInputBase-input': {
      padding: '20px',
      fontWeight: '500',
      fontSize: '12px',
      color: `${color.primary.dark}`
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${color.toast.error} !important`
      }
    }
  },
  '.MuiDialogActions-root .MuiButtonBase-root': {
    borderRadius: '0 !important'
  },
  '#nprogress': {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: '9999',
    '& bar': {
      zIndex: '9999',
      backgroundColor: color.primary.main
    },
    '& peg': {
      zIndex: '9999',

      boxShadow: 'none'
    }
  },
  '.MuiSnackbar-root': {
    zIndex: '2100',
    '& .info': {
      backgroundColor: `${color.toast.info.background} !important`,
      color: `${color.toast.info.font} !important`,
      '& svg:first-child': {
        backgroundColor: `${color.toast.info.font} !important`
      },
      '& svg:last-child': {
        fill: `${color.toast.info.font} !important`
      }
    }
  }
};
