import { useCallback, useState } from 'react';

export function useRequestLoading() {
  const [isLoading, setIsLoading] = useState(false);

  const callbackRequest = useCallback(async cb => {
    try {
      setIsLoading(true);
      await cb();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, []);
  return { requestLoading: isLoading, callbackRequest };
}
