import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import QueueIcon from '@material-ui/icons/Queue';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import { NAME_SPACE } from 'i18n';
import { useLoading } from 'loading';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary, EditModal, useSelectedElement } from 'shared';

import { Result } from './Result';
import { SimulationList } from './Result/Simulation/List/SimulationList';
import { SimulationSave } from './Result/Simulation/Save/SimulationSave';
import { useDashboardStyles } from './dashboard.styles';
import { useAbout } from './useAbout';
import { useHelp } from './useHelp';
import { usePrediction } from './usePredictions';
import { useSimulation } from './useSimulation';

const Dashboard = () => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const classes = useDashboardStyles();
  const $formSimulation = useRef(null);
  const $modal = useRef(null);
  const { selectedElement: selectedCt_exp, handleNew, handleUnselect } = useSelectedElement();
  const { handleLoading } = useLoading();
  const {
    selectedElement: selectedSimulation,
    handleNew: handleLoadNew,
    handleUnselect: handleLoadUnselect
  } = useSelectedElement();

  const {
    simulation,
    errorInfo,
    setSimulationData,
    saveSimulation,
    cleanSimulation,
    onSaveValidations,
    loadSimulation
  } = useSimulation({ t });

  const { prediction, createPrediction, cleanPrediction } = usePrediction();
  const { showHelp } = useHelp();
  const { showAbout } = useAbout();

  const handleSave = () => {
    $formSimulation.current.submit();
    if (!onSaveValidations()) {
      handleNew();
    }
  };

  const handleExecution = () => {
    $formSimulation.current.submit();
    if (!onSaveValidations()) {
      handleLoading(() => createPrediction(simulation));
    }
  };

  const handleNewSimulation = () => {
    cleanSimulation();
    cleanPrediction();
  };

  const buttons = [
    {
      label: t('common:actions.new'),
      startIcon: <QueueIcon />,
      onClick: handleNewSimulation
    },
    {
      label: t('common:actions.open'),
      startIcon: <AddIcon />,
      onClick: handleLoadNew
    },
    {
      label: t('common:actions.execute'),
      startIcon: <SettingsIcon />,
      onClick: handleExecution
    },
    {
      label: t('common:actions.save'),
      startIcon: <SaveAltIcon />,
      onClick: handleSave,
      disabled: !prediction
    },
    {
      label: t('common:actions.about'),
      startIcon: <InfoIcon />,
      onClick: showAbout
    },
    { label: t('common:actions.help'), startIcon: <HelpOutlineIcon />, onClick: showHelp }
  ];

  const handleSubmitSave = async inputs => {
    handleLoading(async () => await saveSimulation(inputs));
    return true;
  };

  return (
    <Grid container>
      <Grid className={classes.mainContainer} container>
        <Typography variant="h6" component="h2">
          {t('title')}
        </Typography>
        <Typography variant="body2" component="p">
          {t('webDescription')}
        </Typography>
        <Typography variant="body2" component="p">
          {t('moreInfo')}
        </Typography>
        <Grid className={classes.containerBtn}>
          {buttons.map(btn => (
            <ButtonPrimary
              size="large"
              startIcon={btn.startIcon}
              key={btn.label}
              disabled={btn.disabled ?? false}
              className={classes.btnBar}
              onClick={btn.onClick}
            >
              {btn.label}
            </ButtonPrimary>
          ))}
        </Grid>
      </Grid>
      <Result
        formRef={$formSimulation}
        {...{ simulation, setSimulationData, errorInfo, prediction, cleanPrediction }}
      />
      <EditModal
        entity={selectedCt_exp}
        handleCloseEditModal={handleUnselect}
        onSubmitEditModal={handleSubmitSave}
        btnKeepCreating={false}
        title={t('simulations:actions.saveSimulation')}
      >
        <SimulationSave formRef={$modal} {...{ simulation, setSimulationData }} />
      </EditModal>
      {selectedSimulation && (
        <SimulationList
          {...{ selectedSimulation, handleLoadUnselect, handleSubmitSave, loadSimulation, cleanPrediction }}
        />
      )}
    </Grid>
  );
};

export default Dashboard;
