import { ROUTES } from 'app.routes.const';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services';
import { authHelper, useHandleRequest } from 'shared';
import { authStorage } from './authStorage';

const { createContext, useState, useContext, useCallback, useEffect } = require('react');

const AuthContext = createContext({
  isLogged: false
});

function userInfoMapper(user) {
  return {
    id: user.id,
    email: user.email,
    roles: user.roles
  };
}

export function AuthProvider({ children }) {
  const { request, setErrorInfo, errorInfo } = useHandleRequest();
  const [user, setUser] = useState({});

  const [isLogged, setIsLogged] = useState(false);

  const navigate = useNavigate();

  const clear = useCallback(() => {
    authStorage.setToken('');
    authStorage.setRefreshToken('');
    setUser({});
    setIsLogged(false);
    navigate(ROUTES.AUTH.LOGIN);
  }, [navigate]);

  const setUserLogged = useCallback(
    async _userTokenData => {
      authStorage.setToken(_userTokenData.token);
      authStorage.setRefreshToken(_userTokenData.refreshToken);
      const userInfo = authHelper.claimDeserializer(_userTokenData.token);
      setUser(userInfoMapper(userInfo));
      setIsLogged(true);
      setErrorInfo(null);
    },
    [setErrorInfo]
  );

  const getUserData = async () => {
    await request(async () => {
      try {
        const token = authStorage.getToken();
        if (token) {
          const userInfo = authHelper.claimDeserializer(token);
          setUser(userInfoMapper(userInfo));
          setIsLogged(!!token);
          return token;
        }
      } catch (error) {
        clear();
      }
    }, false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const login = useCallback(
    async _user => {
      await request(async () => {
        const response = await auth.login(_user);
        if (response?.data) {
          await setUserLogged(response.data);
        }
      });
    },
    [request, setUserLogged]
  );

  const signup = useCallback(
    async _user => {
      return await request(async () => {
        const response = await auth.signup(_user);
        return response.data;
      });
    },
    [request]
  );

  const logout = useCallback(async () => {
    await request(async () => {
      const refreshToken = authStorage.getRefreshToken();
      auth.logout(refreshToken);
      clear();
    });
  }, [request, clear]);

  const value = { isLogged, login, logout, errorInfo, signup, user };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
}
