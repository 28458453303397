import { AuthLayout, PrivateLayout } from 'Layouts';
import { ForgotPassword, Login, Signup } from 'Pages/Authentication';
import { ResetPassword } from 'Pages/Authentication/ResetPassword';
import Dashboard from 'Pages/Dashboard/Dashboard';
import { ROUTES } from 'app.routes.const';
import { useAuth } from 'auth/useAuth';
import { Route, Routes } from 'react-router-dom';

export const App = () => {
  const { isLogged } = useAuth();
  return (
    <Routes>
      <Route element={<AuthLayout {...{ isLogged }} />}>
        <Route path={ROUTES.AUTH.SIGNUP} element={<Signup />} />
        <Route path={ROUTES.AUTH.LOGIN} element={<Login />} />
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.AUTH.CHANGE_PASSWORD} element={<ResetPassword />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route path={ROUTES.HOME} element={<Dashboard />} />
      </Route>
    </Routes>
  );
};
