import { makeStyles } from '@material-ui/core';

export const useAboutStyles = makeStyles(theme => ({
  title: {
    fontSize: '16px',
    fontWeight: 700
  },
  list: {
    listStyleType: 'none'
  },
  form: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    '& img': {
      width: '250px'
    }
  },
  imgQ: {
    '& img': {
      width: '20px',
      verticalAlign: 'middle'
    }
  },
  imgKakd: {
    '& img': {
      width: '65px',
      verticalAlign: 'middle'
    }
  },
  imgQm: {
    '& img': {
      width: '35px',
      verticalAlign: 'middle'
    }
  },
  imgCe: {
    '& img': {
      width: '25px',
      verticalAlign: 'middle'
    }
  },
  imgR2: {
    '& img': {
      width: '25px',
      verticalAlign: 'middle'
    }
  },
  imgKa: {
    '& img': {
      width: '30px',
      verticalAlign: 'middle'
    }
  }
}));
