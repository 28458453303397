import { makeStyles } from '@material-ui/core';
import { color } from '../../styles/color.styles';

export const useDashboardStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: `${color.primary.main}`,
    color: 'white',
    display: 'flex',
    padding: '3rem 20rem 0rem 20rem',
    gap: '1rem'
  },
  containerBtn: {
    backgroundColor: `${color.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    borderTop: '1px solid white'
  },
  btnBar: {
    padding: '.5rem',
    width: '100%',
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    '&:not(:last-child)': {
      borderRight: '1px solid white'
    },
    '& .MuiSvgIcon-root': {
      fill: 'white'
    }
  },
  modal: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '70rem'
    }
  }
}));
