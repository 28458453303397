import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useResultStyles = makeStyles(theme => ({
  form: {
    padding: '1rem 20rem',
    width: '100%'
  },
  table: {
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  info: {
    fontWeight: 600,
    width: '100%'
  },
  title: {
    width: '100%',
    color: `${color.primary.main}`,
    fontWeight: 500,
    fontSize: '1.2rem',
    marginBottom: 0
  },
  description: {
    marginTop: '.5rem',
    marginBottom: '0rem'
  }
}));
