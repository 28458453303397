import { makeStyles } from '@material-ui/core';

export const useSimulationTableStyles = makeStyles(theme => ({
  container: {
    marginTop: '1rem'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    width: '100%',
    padding: '1rem 0'
  },
  error: {
    marginBottom: '1rem'
  },
  modal: {
    '& .MuiPaper-root .MuiDialogContent-root': {
      overflow: 'hidden',
      marginBottom: '1rem',
    }
  }
}));
