function sortByKeyAsc(array, key) {
  return array.sort(function (a, b) {
    let x = Number(a[key]);
    let y = Number(b[key]);
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

function sortByTwoProperties(array, prop1, prop2) {
  if (!Array.isArray(array) || typeof prop1 !== 'string' || typeof prop2 !== 'string') {
    return;
  }
  array.sort(function (a, b) {
    if (a[prop1] < b[prop1]) {
      return -1;
    } else if (a[prop1] > b[prop1]) {
      return 1;
    } else {
      if (a[prop2] < b[prop2]) {
        return -1;
      } else if (a[prop2] > b[prop2]) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return array;
}

export const arrayHelper = {
  sortByKeyAsc,
  sortByTwoProperties
};
