import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useButtonPrimary = makeStyles(theme => ({
  button: {
    boxShadow: 'none',
    background: 'transparent',
    textTransform: 'none',
    fontWeight: 600,
    border: `1px solid ${color.primary.main}`,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    fontSize: '14px',
    height: '100%',
    '&:hover': {
      backgroundColor: `${color.primary.main}`,
      boxShadow: 'none',
      color: 'white',
      '& .MuiSvgIcon-root': {
        fill: 'white'
      }
    },
    '& .MuiSvgIcon-root': {
      fill: `${color.primary.main}`
    },

    '&:disabled': {
      cursor: 'not-allowed !important',
      background: theme.palette.buttons.disabled.background,
      color: theme.palette.buttons.disabled.text,
      borderColor: theme.palette.buttons.disabled.border,
      '& .MuiSvgIcon-root': {
        fill: `${theme.palette.buttons.disabled.border}`
      }
    }
  }
}));
