import { DialogModal } from '@engloba-tech/englobity';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import theme from '../../styles';

export function execConfirmModal({
  title,
  description,
  confirmText,
  declineText,
  onConfirm,
  onCancel,
  children,
  onlyClose = false,
  className
}) {
  return new Promise((resolve, reject) => {
    const idGenerated = shortid.generate();
    const entryPointToasterDom = document.createElement('div');
    entryPointToasterDom.setAttribute('id', idGenerated);
    const global = document.getElementById('global');
    global.appendChild(entryPointToasterDom);

    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DialogModal
          fullWidth
          title={title}
          description={description}
          isOpen
          className={className}
          buttons={
            !onlyClose
              ? [
                  {
                    children: <CloseIcon />,
                    type: 'secondary',
                    text: declineText,
                    onClick: () => {
                      ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                      global.removeChild(entryPointToasterDom);
                      onCancel && onCancel();
                      reject('User clicked No');
                    }
                  },
                  {
                    children: <DoneIcon />,
                    type: 'primary',
                    text: confirmText,
                    onClick: () => {
                      ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                      global.removeChild(entryPointToasterDom);
                      onConfirm && onConfirm();
                      resolve('User clicked Yes');
                    }
                  }
                ]
              : [
                  {
                    children: <CloseIcon />,
                    type: 'secondary',
                    text: declineText,
                    onClick: () => {
                      ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
                      global.removeChild(entryPointToasterDom);
                      onCancel && onCancel();
                      reject('User clicked No');
                    }
                  }
                ]
          }
          onClose={() => {
            ReactDOM.unmountComponentAtNode(document.getElementById(idGenerated));
            global.removeChild(entryPointToasterDom);
            onCancel && onCancel();
          }}
        >
          {children}
        </DialogModal>
      </ThemeProvider>,
      document.getElementById(idGenerated)
    );
  });
}
