import { Form, Input, TextArea } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SimulationSave = ({ simulation, setSimulationData, onSubmit, formRef }) => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const [inputs, setInputs] = useState({ name: '', description: '' });

  const handleChange = ({ target }) => {
    setInputs(prevalue => ({
      ...prevalue,
      [target.name]: target.value
    }));
  };

  function handleSubmit() {
    onSubmit(inputs);
  }

  return (
    <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            required
            name="name"
            label={t('properties.name')}
            variant="standard"
            value={inputs?.name || ''}
            onChange={e => handleChange(e)}
            inputProps={{ 'aria-label': t('properties.name') }}
            validators={['required', 'maxStringLength:255']}
            errorMessages={[t('common:validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
            InputProps={{
              disableUnderline: true
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            name="description"
            label={t('properties.description')}
            variant="standard"
            value={inputs?.description || ''}
            onChange={e => handleChange(e)}
            inputProps={{ 'aria-label': t('properties.description') }}
            validators={['maxStringLength:5000']}
            errorMessages={[t('common:validations.maxStringLength', { length: 5000 })]}
            InputProps={{
              disableUnderline: true
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
