import { simulations } from 'services';
import { useViewTableData } from 'shared';

export function useSimulationsPaginated() {
  const {
    data: simulationsData,
    get: getSimulations,
    deleteElements: deleteSimulations,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    setData,
    undoData,
    setRequestParams,
    handleCloseEditModal,
    resetFilteredCells
  } = useViewTableData({
    service: simulations,
    mainKey: 'name'
  });
  return {
    simulations: simulationsData,
    errorInfo: getDeleteErrorKey,
    setErrorInfo,
    getSimulations,
    deleteSimulations,
    setData,
    undoData,
    setRequestParams,
    handleCloseEditModal,
    resetFilteredCells,
  };
}
