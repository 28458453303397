import { NAME_SPACE } from 'i18n';
import { useTranslation } from 'react-i18next';
import { execConfirmModal } from 'shared';
import { Help } from './Help';
import { useHelpStyles } from './Help/help.styles';

export const useHelp = () => {
  const { t } = useTranslation(NAME_SPACE.HELP);
  const classes = useHelpStyles();

  const showHelp = () => {
    execConfirmModal({
      title: t('help'),
      declineText: t('common:actions.close'),
      children: <Help />,
      onlyClose: true,
      className: classes.modal
    });
  };

  return { showHelp };
};
