import { arrayHelper } from './array.helper';

function mapFromSimulation(simulation = {}) {
  const sortCtExps = arrayHelper.sortByKeyAsc(simulation?.ctExps, 'texp');
  const prediction = {
    ...simulation,
    Lb: Number(simulation.lb),
    Db: Number(simulation.db),
    Q: Number(simulation.q),
    texp: sortCtExps.map(ct => parseFloat(ct.texp)
    
    ) || [],
    cciexp: sortCtExps.map(ct => Number(ct.cexpci)) || []
  };
  return JSON.parse(JSON.stringify(prediction));
}

export const predictionHelper = {
  mapFromSimulation
};
