import { useCallback, useMemo } from 'react';
import { arrayHelper } from 'shared/helper/array.helper';

export function usePredictionBuildTable({ prediction, t }) {
  const transformData = useCallback(() => {
    const keys = ['n', 'm', 'tau', 'sse', 'r2'];
    const result = [];
    for (let i = 0; i < prediction.fitResults.length; i++) {
      const item = {};
      for (let j = 0; j < keys.length; j++) {
        item[keys[j]] = prediction.fitResults[i][j] || '-';
      }
      result.push(item);
    }
    return arrayHelper.sortByTwoProperties(result, 'm', 'n');
  }, [prediction.fitResults]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'm',
        numeric: false,
        disablePadding: false,
        label: t('properties.m')
      },
      {
        id: 'n',
        numeric: false,
        disablePadding: true,
        label: t('properties.n')
      },
      {
        id: 'tau',
        numeric: false,
        disablePadding: false,
        label: t('properties.tau')
      },
      {
        id: 'sse',
        numeric: false,
        disablePadding: false,
        label: t('properties.sse')
      },
      {
        id: 'r2',
        numeric: false,
        disablePadding: false,
        label: t('properties.r2')
      }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!prediction?.fitResults) {
      return [];
    }
    return transformData();
  }, [prediction, transformData]);

  return { headCells, rows };
}
