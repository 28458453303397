import { ErrorSummary, Form, Input, SuccessSummary } from '@engloba-tech/englobity';
import { Grid, IconButton, InputAdornment, Tooltip, Typography } from '@material-ui/core';
import { KeyboardArrowLeft, Visibility, VisibilityOff } from '@material-ui/icons';
import { ROUTES } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { useLoading } from 'loading';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services';
import { ButtonPrimary, useHandleRequest } from 'shared';
import { useAuthStyles } from '../auth.styles';

export const ResetPassword = () => {
  const { request, errorInfo } = useHandleRequest();
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const classes = useAuthStyles();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = encodeURI(urlParams.get('token'));
  const email = encodeURI(urlParams.get('email'));
  const { handleLoading } = useLoading();

  const [inputs, setInputs] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [changeDone, setChangeDone] = useState(false);
  const [isValidToken, setIsValidToken] = useState(null);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword(showRepeatPassword => !showRepeatPassword);

  const handleField = ({ target }) => setInputs(prevInputs => ({ ...prevInputs, [target.name]: target.value }));

  const handleResetPassword = useCallback(async () => {
    setChangeDone(false);
    await request(async () => {
      handleLoading(async () => {
        const response = await auth.resetPassword(email, token, inputs.newPassword);
        response && setChangeDone(true);
      });
    }, false);
  }, [email, inputs.newPassword, request, token, handleLoading]);

  useEffect(() => {
    async function checkTokenValidity() {
      await request(async () => {
        const isValid = await auth.checkTokenValidity(email, token);
        setIsValidToken(isValid);
      }, false);
    }
    checkTokenValidity();
  }, [email, request, token]);

  return (
    <>
      <Typography variant="h5" component="h4" className={classes.title}>
        {t('resetPassword.title')}
      </Typography>
      {isValidToken && (
        <>
          <Typography gutterBottom component="div" variant="div">
            {!changeDone && t('resetPassword.description')}
          </Typography>
          <Form onSubmit={handleResetPassword} noValidate className={classes.form}>
            <Grid container spacing={2} className={classes.mt}>
              <Grid item xs={12}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  arrow
                  title={<>{t('resetPassword.validations.passwordPolicy')}</>}
                >
                  <Input
                    className={classes.input}
                    required
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    label={t('resetPassword.properties.password')}
                    variant="standard"
                    value={inputs?.newPassword || ''}
                    onChange={e => handleField(e)}
                    inputProps={{ 'aria-label': t('signup.properties.password') }}
                    validators={['required']}
                    errorMessages={[t('common:validations.required')]}
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Input
                  className={classes.input}
                  required
                  type={showRepeatPassword ? 'text' : 'password'}
                  name="repeatNewPassword"
                  variant="standard"
                  label={t('resetPassword.properties.repeatPassword')}
                  value={inputs?.repeatNewPassword || ''}
                  onChange={e => handleField(e)}
                  inputProps={{ 'aria-label': t('resetPassword.properties.repeatPassword') }}
                  validators={['required', `isPasswordMatch:${inputs.newPassword}`]}
                  errorMessages={[t('common:validations.required'), t('resetPassword.validations.passwordMissmatch')]}
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowRepeatPassword}>
                          {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonPrimary type="submit" color="primary" className={classes.submit}>
                  {t('resetPassword.properties.submit')}
                </ButtonPrimary>
              </Grid>
              {errorInfo && (
                <Grid item xs={12}>
                  <ErrorSummary text={t(errorInfo.message)} className={classes.summary} />
                </Grid>
              )}
              {changeDone && (
                <Grid item xs={12}>
                  <SuccessSummary text={t('resetPassword.success')} className={classes.summary} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography onClick={() => navigate(ROUTES.AUTH.LOGIN)} className={classes.link}>
                  <KeyboardArrowLeft />
                  {t('resetPassword.goBack')}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
      {!isValidToken && (
        <>
          <Typography gutterBottom component="div" variant="div">
            {t('resetPassword.invalidLinkOrInvalidCredentials')}
          </Typography>
          <Grid item xs={12}>
            <Typography onClick={() => navigate(ROUTES.AUTH.LOGIN)} className={classes.link}>
              <KeyboardArrowLeft />
              {t('resetPassword.goBack')}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};
