import { createEnglobaMaterialTheme } from '@engloba-tech/englobity';
import { color } from './color.styles';
import { globals } from './globals.styles';

// create material engloba theme
const fontFamily = ['Montserrat', 'Roboto'].join(',');
const theme = createEnglobaMaterialTheme(color, globals, fontFamily, {
  shape: {
    borderRadius: 0
  }
});

export default theme;
