import { Container, SvgIcon } from '@material-ui/core';
import { ROUTES } from 'app.routes.const';
import { ReactComponent as MathColLogo } from 'assets/images/logox2-light.svg';
import { useAuth } from 'auth/useAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthLayoutStyles } from './authLayout.styles';

export const AuthLayout = () => {
  const classes = useAuthLayoutStyles();
  const { isLogged } = useAuth();

  if (isLogged) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Container component="main" maxWidth="md" className={classes.main}>
      <SvgIcon component={MathColLogo} viewBox="0 0 390 90" className={classes.svg} />
      <Container className={classes.formContainer}>
        <Outlet />
      </Container>
    </Container>
  );
};
