import { Button } from '@material-ui/core';
import React from 'react';
import { useButtonPrimary } from './buttonPrimary.styles';

export const ButtonPrimary = ({ children, className, ...props }) => {
  const classes = useButtonPrimary();
  return (
    <Button className={`${className} ${classes.button}`} {...props}>
      {children}
    </Button>
  );
};
