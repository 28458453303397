import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const usePredictionStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(248,236,238,.5)',
    padding: '1rem 20rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1rem',
    '& .MuiBox-root': {
      boxSizing: 'border-box',
      width: '32%',
      border: `2px solid ${color.primary.lightest}`,
      height: 'auto'
    },
    '& .viewtable': {
      //border: '1px solid blue'
    },
  },
  title: {
    width: '100%',
    color: `${color.primary.main}`,
    fontWeight: 500,
    fontSize: '1.2rem'
  }
}));
