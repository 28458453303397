import { Input } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResultStyles } from '../../result.styles';

export const SimulationForm = ({ simulation, setSimulationData }) => {
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);
  const classes = useResultStyles();
  const handleChange = ({ target }) => {
    setSimulationData(prevalue => ({
      ...prevalue,
      [target.name]: target.value
    }));
  };

  return (
    <Grid container spacing={2} className={classes.table}>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          required
          name="lb"
          label={t('properties.lb')}
          variant="standard"
          value={simulation?.lb || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.lb') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="db"
          label={t('properties.db')}
          variant="standard"
          value={simulation?.db || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.db') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="d"
          label={t('properties.d')}
          variant="standard"
          value={simulation?.d || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.d') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="q"
          label={t('properties.q')}
          variant="standard"
          value={simulation?.q || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.q') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="e"
          label={t('properties.e')}
          variant="standard"
          value={simulation?.e || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.e') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="rhoa"
          label={t('properties.rhoa')}
          variant="standard"
          value={simulation?.rhoa || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.rhoa') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          className={classes.input}
          type="number"
          min="0"
          required
          name="qm"
          label={t('properties.qm')}
          variant="standard"
          value={simulation?.qm || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.qm') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          type="number"
          min="0"
          required
          name="qeexp"
          label={t('properties.qeexp')}
          variant="standard"
          value={simulation?.qeexp || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.qeexp') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          type="number"
          min="0"
          required
          name="ci"
          label={t('properties.ci')}
          variant="standard"
          value={simulation?.ci || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.ci') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Input
          type="number"
          min="0"
          required
          name="t12"
          label={t('properties.t12')}
          variant="standard"
          value={simulation?.t12 || ''}
          onChange={e => handleChange(e)}
          inputProps={{ 'aria-label': t('properties.t12') }}
          validators={['required', 'minNumber:0']}
          errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
          InputProps={{
            disableUnderline: true
          }}
        />
      </Grid>
    </Grid>
  );
};
