import { apiHelper } from 'shared';
import { http } from './http';

const controllerName = 'simulations';

class Simulations {
  get(paging, sorting, filters) {
    let queryString = '';
    queryString = apiHelper.composeQueryString({
      ...paging,
      ...sorting,
      ...filters
    });

    return http.get(`/${controllerName}/${queryString}`);
  }

  getById(id) {
    return http.get(`/${controllerName}/${id}`);
  }

  create(simulation) {
    return http.post(`/${controllerName}`, simulation);
  }

  delete(id, multiple) {
    return http.delete(`/${controllerName}/${id}`, { multiple });
  }
}

export const simulations = new Simulations();
