function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

function isEmpty(obj) {
  return Object.values(obj).some(u => u.length === 0);
}

function isAnObject(obj) {
  return typeof obj === 'object' && obj !== null;
}

function isArray(myArray) {
  return myArray.constructor.toString().indexOf('Array') > -1;
}

export const objectHelper = {
  deepClone,
  isEmpty,
  isAnObject,
  isArray
};
