import { useCallback, useState } from 'react';

export function useSelectedElement() {
  const [selectedElement, setSelectedElement] = useState(null);

  const handleSelect = useCallback(element => setSelectedElement(element), []);

  const handleNew = useCallback(() => setSelectedElement({}), []);

  const handleUnselect = useCallback(() => setSelectedElement(null), []);

  return { selectedElement, handleSelect, handleNew, handleUnselect };
}
