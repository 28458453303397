import { NAME_SPACE } from 'i18n';
import { useTranslation } from 'react-i18next';
import { execConfirmModal } from 'shared';
import { About } from './About/About';
import { useHelpStyles } from './Help/help.styles';

export const useAbout = () => {
  const { t } = useTranslation(NAME_SPACE.ABOUT);
  const classes = useHelpStyles();

  const showAbout = () => {
    execConfirmModal({
      title: t('about'),
      declineText: t('common:actions.close'),
      children: <About />,
      onlyClose: true,
      className: classes.modal
    });
  };

  return { showAbout };
};
