import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from './assets/locales';

export const LANGUAGES = [
  { code: 'en-EN', label: 'en' },
  { code: 'es-ES', label: 'es' },
  { code: 'ca-CA', label: 'ca' }
];

export const NAME_SPACE = {
  COMMON: 'common',
  SIMULATIONS: 'simulations',
  PREDICTIONS: 'predictions',
  HELP: 'help',
  ABOUT: 'about',
  AUTHENTICATION: 'authentication',
  USERS: 'users'
};

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

export const langSelector = () => {
  if (getLanguage().length === 2) {
    return `${getLanguage()}-${getLanguage().toUpperCase()}`;
  } else {
    return getLanguage();
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: [LANGUAGES.ENGLISH, LANGUAGES.SPANISH],
    ns: Object.values(NAME_SPACE),
    defaultNS: NAME_SPACE.COMMON,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
