import { makeStyles } from '@material-ui/core';
import { color } from '../../styles/color.styles';

export const useFooterStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    gap: '.3rem',
    width: '100%',
    color: `${color.primary.main}`,
    fontSize: '12px',
    padding: '0 20rem',
    borderTop: `2px solid ${color.primary.main}`,
    marginTop: '2rem'
  }
}));
