import { useMemo } from 'react';

export function useDataBuildTable({ simulation, t }) {
  const headCells = useMemo(() => {
    return [
      {
        id: 'texp',
        numeric: false,
        disablePadding: false,
        label: t('properties.texp')
      },
      {
        id: 'cexpci',
        numeric: false,
        disablePadding: false,
        label: t('properties.cexpci')
      }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!simulation?.ctExps) {
      return [];
    }
    return simulation?.ctExps.map(d => ({
      texp: d.texp,
      cexpci: d.cexpci,
      id: d.id
    }));
  }, [simulation]);

  return { headCells, rows };
}
