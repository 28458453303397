import { Form, Input, SuccessSummary } from '@engloba-tech/englobity';
import { Grid, Typography } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { ROUTES } from 'app.routes.const';
import { NAME_SPACE } from 'i18n';
import { useLoading } from 'loading';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services';
import { ButtonPrimary, useHandleRequest } from 'shared';
import { useAuthStyles } from '../auth.styles';

export const ForgotPassword = () => {
  const { t } = useTranslation(NAME_SPACE.AUTHENTICATION);
  const classes = useAuthStyles();
  const { handleLoading } = useLoading();
  const { request } = useHandleRequest();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [sentEmail, setSentEmail] = useState(false);

  const handleField = ({ target }) => {
    setSentEmail(false);
    setEmail(target.value);
  };

  const handleForgotPassword = useCallback(async () => {
    setSentEmail(false);
    await request(async () => {
      handleLoading(async () => {
        await auth.forgotPassword(email);
      });
      setSentEmail(true);
    }, false);
  }, [email, request, handleLoading]);

  return (
    <>
      <Typography variant="h5" component="h4" className={classes.title}>
        {t('forgotPassword.title')}
      </Typography>
      <Typography variant="p">{t('forgotPassword.instructions')}</Typography>
      <Form onSubmit={handleForgotPassword} noValidate className={classes.form}>
        <Grid container spacing={2} className={classes.mt}>
          <Grid item xs={12}>
            <Input
              type="email"
              required
              className={classes.input}
              name="email"
              label={t('login.properties.email')}
              variant="standard"
              value={email || ''}
              onChange={e => handleField(e)}
              inputProps={{ 'aria-label': 'email' }}
              validators={['required', 'isEmail']}
              errorMessages={[t('common:validations.required'), t('common:validations.isEmail')]}
              InputProps={{
                disableUnderline: true
              }}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonPrimary type="submit" color="primary" className={classes.submit}>
              {t('forgotPassword.sendEmail')}
            </ButtonPrimary>
          </Grid>
          {sentEmail && (
            <Grid item xs={12}>
              <SuccessSummary text={t('forgotPassword.emailSentSuccessfully')} className={classes.summary} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography onClick={() => navigate(ROUTES.AUTH.LOGIN)} className={classes.link}>
              <KeyboardArrowLeft />
              {t('forgotPassword.goBack')}
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
