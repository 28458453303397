import { Form, Input } from '@engloba-tech/englobity';
import { Grid } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const FormTableSimulation = ({ formRef, onSubmit, ct_exp }) => {
  const [inputs, setInputs] = useState({ ...ct_exp } || {});
  const { t } = useTranslation(NAME_SPACE.SIMULATIONS);

  useEffect(() => {
    setInputs({ ...ct_exp });
  }, [ct_exp]);

  function handleSubmit() {
    onSubmit(inputs);
  }

  const handleChange = ({ target }) => {
    setInputs(prevInputs => ({ ...prevInputs, [target.name]: target.value }));
  };

  return (
    <Form elementRef={formRef} onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Input
            type="number"
            required
            name="texp"
            label={t('properties.texp')}
            variant="standard"
            value={inputs?.texp || ''}
            onChange={e => handleChange(e)}
            inputProps={{ 'aria-label': t('properties.abbreviation') }}
            validators={['required', 'minNumber:0']}
            errorMessages={[t('common:validations.required'), t('common:validations.minNumber')]}
            InputProps={{
              disableUnderline: true
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            type="number"
            required
            name="cexpci"
            label={t('properties.cexpci')}
            variant="standard"
            value={inputs?.cexpci || ''}
            onChange={e => handleChange(e)}
            inputProps={{ 'aria-label': t('properties.abbreviation') }}
            validators={['required', 'minNumber:0']}
            errorMessages={[t('common:validations.required')]}
            InputProps={{
              disableUnderline: true
            }}
          />
        </Grid>
      </Grid>
    </Form>
  );
};
