import { makeStyles } from '@material-ui/core';
import { color } from 'styles/color.styles';

export const useAuthLayoutStyles = makeStyles(theme => ({
  main: {
    width: '100vw',
    maxWidth: '100vw',
    overflow: 'hidden',
    height: '100vh',
    background: `linear-gradient(80deg, ${color.primary.dark}, ${color.secondary.lightest})`,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    background: 'white',
    width: '450px',
    minHeight: '450px',
    height: 'auto',
    padding: '30px',
    paddingBottom: '50px'
  },
  svg: {
    width: '180px',
    height: '50px',
    alignSelf: 'center'
  }
}));
