import { APIS } from 'app.apis.const';
import { http } from './http';

const controllerName = 'predictions';

class Predictions {
  create(prediction) {
    return http.post(`/${controllerName}/id`, prediction, {}, APIS.PREDICTIONS, false);
  }
}

export const predictions = new Predictions();
