import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFooterStyles } from './footer.styles';

export const Footer = () => {
  const classes = useFooterStyles();
  const { t } = useTranslation();
  return (
    <Grid className={classes.footer} container>
      <p>{t('common:centreRecerca')}</p>
      <p>{t('common:rightsReserved')}</p>
    </Grid>
  );
};
